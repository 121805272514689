body {
    margin: 0;
    padding: 0;
    font-family: Barlow, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.DayPickerNavigation__verticalDefault {
    position: fixed !important;
    display: flex;
}

.swiper-container {
    width: 100%;
    height: 100%
}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after, .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: '';
}

.swiper-button-prev, .swiper-button-next {
    background-size: 14px 22px;
    background-repeat: no-repeat;
    z-index: 1000;
}

.swiper-button-prev {
    left: 8px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%230db7df'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-next {
    right: 8px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%230db7df'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev:focus, .swiper-button-next:focus {
    outline: none;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0
}

.swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
    background: white;
}

.swiper-pagination-bullet {
    background: #16405c;
    opacity: 0.25;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 3px;
}

.swiper-pagination-bullet-active {
    background: #0db7df;
    opacity: 1;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 0 !important;
}

/***
 * React base table
 */

.BaseTable__row-cell-text {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0;
}

.BaseTable__table-main .BaseTable__header-cell:first-child, .BaseTable__table-main .BaseTable__row-cell:first-child,
.BaseTable__table-main .BaseTable__header-cell:last-child, .BaseTable__table-main .BaseTable__row-cell:last-child,
.BaseTable__header-cell, .BaseTable__row-cell {
    padding: 0
}

.BaseTable {
    box-shadow: none;
}

.BaseTable__table-main {
    outline: none;
}

.BaseTable__header-row, .BaseTable__row {
    border-bottom: 0;
    background-color: transparent;
}

.BaseTable .BaseTable__header, .BaseTable .BaseTable__body {
    background: transparent;
}

.BaseTable__table-frozen-left {
    background: transparent;
    box-shadow: none;
}

.BaseTable__row:hover, .BaseTable__row--hovered {
    background: transparent;
}


/***************************************
 * 360° Viewer
 ***************************************/

.psv-navbar {
    justify-content: center;
}

.psv-loader {
    border: 4px solid transparent;
}

.image-360-inline .psv-navbar {
    background: transparent;
    z-index: -1;
}

.image-360-inline .psv-container {
    border-radius: 4px;
    color: #16405c;
}

@media (max-width: 600px) {
    .image-360-inline .psv-container {
        border-radius: 0;
    }
}

/***************************************
 * Plyr video player
 ***************************************/

.plyr__video-wrapper {
    padding-bottom: 0 !important;
    background: none;
}

.plyr--video {
    border-radius: 6px;
    background: none;
}

.plyr__volume[hidden], .plyr__volume [hidden] {
    display: flex !important;
}

.plyr__progress__container {
    width: calc(100% - 275px);
}

.plyr__control--overlaid {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
    padding: 30px;
    background: white;
}

.plyr--video .plyr__control:hover {
    background: #0DB7DF;
}

.plyr__control--overlaid svg {
    fill: none;
    stroke: #16405C;
    stroke-width: 2px;
}

.plyr--video .plyr__control:hover svg {
    stroke: white
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
    background: #0DB7DF;
}

.plyr--full-ui input[type=range] {
    color: #0DB7DF;
}


.plyr audio, .plyr video {
    max-height: 50vh;
}

.plyr:fullscreen video {
    max-height: 100vh !important;
}

@media (max-width: 960px) {
    .plyr--video {
        border-radius: 0;
    }

}

@media (max-width: 960px) {
    .plyr audio, .plyr video {
        max-height: 400px;
    }
}

@media (max-width: 600px) {
    .plyr audio, .plyr video {
        max-height: 300px;
    }

    .plyr--video {
        background: #FBFBFD;
    }
}
